footer {
  /* color: #fff; */
  color: #fff;
  /* color: rgb(138, 136, 136); */
  text-align: center;
  padding: 2%;
  /* background-color: #7880dd; */
  background-color: rgb(120, 128, 221);
  font-size: 14px;
  /* border-top: 1px solid #000; */
}

footer a {
  /* color: #fff; */
  color: #fff;
  /* color: rgb(138, 136, 136); */
  text-decoration: underline;
}
