#download-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
.download-btn {
  display: inline-block;
  max-width: 180px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 24px;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #2c3be0;
  background-color: #4653e2;
  border-bottom: 4px solid #1c2696;
}

.download-btn:active {
  transform: translateY(4px);
  border-bottom: none;
}

#createGif-btn {
  background-color: #2c3be0;
  background-color: #4653e2;
  border-bottom: 4px solid #1c2696;
}

.hide {
  display: none;
}
