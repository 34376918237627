.selectdiv {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 5px;
}

/* IE11 hide native button  */
select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: '<>';
  font: 17px 'Consolas', monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/

  top: 28px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  display: block;
  width: 100%;
  max-width: 512px;
  height: 32px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 14px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}
