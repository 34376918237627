.btn-push {
  display: inline-block;
  max-width: 180px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 24px;
  border-radius: 4px;
}

.default {
  display: inline-block;
  max-width: 180px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 24px;
  border-radius: 4px;
}

.default:active {
  transform: translateY(4px);
  border-bottom: none;
}

#reset-btn {
  background-color: #e45744;
  border-bottom: 4px solid #aa3324;
}

#record-btn {
  background-color: #44e451;
  border-bottom: 4px solid #24aa2b;
}

#createGif-btn {
  background-color: #4653e2;
  border-bottom: 4px solid #1c2696;
  margin-left: 15px;
}

.hide {
  display: none;
}

.recording {
  background-color: #44e451;
  border-bottom: 4px solid #24aa2b;
  margin-left: 15px;
}

.invalid {
  background-color: #c7c7c9;
  border-bottom: 4px solid #7a7a7a;
  margin-left: 15px;
}
