.color_picker_button {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 49%;
  height: 32px;
  margin: 5px 0px;
}

.color_picker_button:hover {
  cursor: pointer;
}
