:root {
  --screenWidth: 512px;
  --screenHeight: 256px;
}

body {
  background-color: rgb(240, 240, 250);
}

#container {
  width: 100%;
  box-sizing: border-box;
}

header {
  width: 100%;
  /* background-color: #707bf7; */
}

header img#logo {
  width: 60px;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
}

#inner {
  width: var(--screenWidth);
  margin: 0 auto;
  box-sizing: border-box;
}

.wrapper-by-step {
  width: 100%;
  margin-bottom: 30px;
}

#size-mode-wrapper {
  width: 100%;
}

.select-wrapper {
  display: block;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.btn-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

#down-to-here {
  overflow-y: auto;
  width: 100%;
  height: 30px;
  /* margin-top: 50px; */
}

.down-to-here-hide {
  display: none;
}

.down-to-here-show {
  display: block;
}

@media screen and (min-width: 480px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1300px) {
}
